import axios from 'axios';

const API_URL = 'https://www.googleapis.com/books/v1/volumes';
const MAX_RESULTS = 15;

export const fetchBooks = async (startIndex = 0, maxResults = MAX_RESULTS) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                q: 'inauthor:"Stanislaw Lem"',
                startIndex: startIndex,
                maxResults: maxResults
            }
        });

        const books = response.data.items || [];
        const totalItems = response.data.totalItems || 0;
        const hasNextPage = startIndex + maxResults < totalItems;

        return { books, hasNextPage };
    } catch (error) {
        console.error('Error fetching books', error);
        throw error;
    }
};

export const fetchBookById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    const book = response.data;
    return book;
  } catch (error) {
    console.error('Error fetching book by id:', error);
  }
};
