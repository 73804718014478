import React, { createContext, useState } from 'react';

const BooksContext = createContext();

export const BooksProvider = ({ children }) => {
  const [books, setBooks] = useState([]);

  const addBooks = (newBooks) => {
    setBooks((prevBooks) => [...prevBooks, ...newBooks]);
  };

  const findBookById = (id) => {
    return books.find((book) => book.id === id);
  };

  return (
    <BooksContext.Provider value={{ books, addBooks, findBookById }}>
      {children}
    </BooksContext.Provider>
  );
};

export { BooksContext };